<!--

      This component defines a page. A page is the foundation of almost every
      view - except for slides (= wizards) and the login / registration page.

 -->

<template>
  <ion-page ref="page">
    <ion-header v-if="!hideheader" class="the-header">
      <ion-toolbar class="header-toolbar">
        <ion-buttons slot="start" class="header-buttons">
          <div class="prev-icon" v-if="!hideback" @click="$router.go(-1)">
            <Icon name="foodableArrowBack" />
          </div>
        </ion-buttons>
        <ion-title class="headline">{{ title }}</ion-title>
        <ion-buttons slot="end" class="header-buttons">
          <slot name="header"></slot>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-y="scroll" class="page-ion-content" :scroll-events="scrollevents" @ionScroll="scrollingHappened($event)" ref="ioncontent">
      <ion-header collapse="condense" v-if="!hidelargeheader" class="large-header">
        <ion-toolbar>
          <ion-title class="headline large-header-title">{{ title }}</ion-title>
          <ion-buttons slot="end" collapse="true">
            <slot name="headerlarge"></slot>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div 
        v-if="shoppingList"
        class="shopping-list"
        @click="$store.dispatch('openModal', { name: 'modalshoppinglist' })"
      >
        <Icon name="listOutline" />
        Merkliste
      </div>
      <div 
        :class="[{'padding-bottom': !noPaddingBottom}, {'padding-top': !noPaddingTop}, {'full-height': containsSlides}, {'shopping-list-padding': shoppingList}]"  
        @scroll="scrollingHappened($event)"
      >
        <slot></slot>
      </div>
    </ion-content>
    <slot name="global"></slot>
    <div v-show="$store.getters.internet === 'offline' && !allowoffline" class="offline-overlay">
      <div class="centered">
        <Icon name="globeOutline" class="internet-icon" />
        <div class="headline">Keine Internetverbindung</div>
        <div class="secondary-text">Profil bearbeiten und Einkaufen erfordert eine Internetverbindung. Du kannst ohne Internet jedoch Kochen und Essen.</div>
        <br><br>
        <ion-button class="cta-button" @click="$store.dispatch('goTo', 'cooking')">Jetzt zum Kochen & Essen</ion-button>
        <ion-button class="cta-button-2" @click="$store.dispatch('checkInternetConnection')">Nochmal probieren</ion-button>
      </div>
    </div>
    <!-- <div v-show="$store.getters.internet === 'server_down' && !allowoffline" class="offline-overlay">
      <div class="centered">
        <Icon name="planetOutline" class="internet-icon" />
        <div class="headline">Unser Server wurde von einem Meteoriten getroffen</div>
        <div class="secondary-text">Wir reparieren ihn so schnell wir können. Du kannst trotzdem Kochen und Essen.</div>
        <br><br>
        <ion-button class="cta-button" @click="$store.dispatch('goTo', 'cooking')">Jetzt zum Kochen & Essen</ion-button>
        <ion-button class="cta-button-2" @click="$store.dispatch('checkInternetConnection')">Nochmal probieren</ion-button>
      </div>
    </div> -->
  </ion-page>
</template>

<script>
import { Plugins } from '@capacitor/core'
const { Keyboard } = Plugins

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonPage
} from '@ionic/vue'

import { createGesture } from '@ionic/vue'

export default {
  name: 'page',
  props: [
    'title',
    'hideheader',
    'hideback',
    'hidelargeheader',
    'scroll',
    'scrollevents',
    'allowoffline',
    'noPaddingTop',
    'noPaddingBottom',
    'containsSlides',
    'shoppingList',
    'disableSwipeBack'
  ],
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
    IonPage
  },
  methods: {
    scrollingHappened(event) {
      if (event && event.detail && event.detail.deltaY && event.detail.deltaY > 25) {
        if (process.env.NODE_ENV !== 'development') {
          Keyboard.hide()
        }
      }
      this.$emit('scrolled', event)
    }
  },
  mounted () {
    if (this.disableSwipeBack) {
      const gesture = createGesture({
        el: this.$refs.page.$el,
        threshold: 0,
        gestureName: 'goback-swipe',
        gesturePriority: 40.5, // priority of swipe to go back is 40
        // onMove: ev => console.log(ev)
      });
      gesture.enable(true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../theme/main.scss';

.header-toolbar {
  --border-width: 0 !important;
}

.large-header {
  ion-toolbar {
    padding-top: 2px !important;
    margin-top: -2px !important;
    --border-width: 0;
  }
  ion-title {
    margin: 0 0 0 20px;
    padding: 0;
  }
}

.the-header, .large-header {
  background: #{$col-pastell-green};
  ion-toolbar {
    --background: #{$col-pastell-green};
  }
}

.large-header-title {
  text-align: left;
  margin: 0 20px;
  color: $col-font;
}

.header-buttons {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-right: 10px;
  max-width: 80px;
}

.offline-overlay {
  height: 100vh;
  width: 100vw;
  background: $col-white;
  position: fixed;
  z-index: 999;
  div {
    padding: 0 20px;
  }
}

.page-ion-content {
  position: relative;
  text-align: center;
  --background: #{$app-bg};
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.internet-icon {
  height: 150px;
  width: 150px;
  --stroke: #{$col-light-gray-font};
  margin: 0 auto;
}

.prev-icon {
  stroke: #{$col-font};
  height: 24px;
  width: 24px;
  margin: 0 0 0 16px;
  display: flex;
  align-items: center;
}

.full-height {
  height: 100%;
}

.shopping-list {
  @extend .btn;
  color: white;
  display: inline-flex;
  align-items: center;
  margin-right: 20px;
  position: absolute;
  top: 16px;
  right: 0;
  background: $col-font-green;
  z-index: 110;
  div {
    height: 22px;
    width: 22px;
    fill: white;
    --stroke: white;
    margin: -4px 5px -2px 0; 
  }
}

.shopping-list-padding {
  padding-top: 40px;
}
</style>
